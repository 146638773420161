import { ClientContext, GraphQLClient } from "graphql-hooks";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Footer } from "./components/Footer/Footer";
import { NavBar } from "./components/Navbar/Navbar";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { HomePage } from "./pages/Home/HomePage";
import { ProductPage } from "./pages/ProductPage/product.page";
import { StorePage } from "./pages/StorePage/store.page";

const endpoint = process.env.PREVIEW_MODE
  ? "https://graphql.datocms.com/preview"
  : "https://graphql.datocms.com/";

const client = new GraphQLClient({
  url: endpoint,
  headers: {
    Authorization: process.env.REACT_APP_DATO_CMS || "",
  },
});

function App() {
  return (
    <ClientContext.Provider value={client}>
      <BrowserRouter>
        <NavBar />

        <div className="main-container">
          <Switch>
            <Route path="/about" component={AboutPage} />
            <Route path="/store/:slug" exact component={ProductPage} />
            <Route path="/store" exact component={StorePage} />
            <Route path="/contact" component={() => <>Contact</>} />
            <Route path="/donate" component={() => <>Donate</>} />
            <Route path="/" component={HomePage} />
          </Switch>
        </div>

        <Footer />
      </BrowserRouter>
    </ClientContext.Provider>
  );
}

export default App;
