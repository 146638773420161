import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import React from "react";
import { SLIDES } from "../../assets/images/slides/index";
import "./carousel.scss";

export const Carousel: React.FC = () => {
  const renderSlides = SLIDES.map((s, i) => {
    return (
      <Slide index={i}>
        <img src={s} alt="slider" />
      </Slide>
    );
  });

  return (
    <div className="carousel-container">
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={300}
        totalSlides={SLIDES.length}
        isPlaying={true}
      >
        <Slider>{renderSlides}</Slider>
      </CarouselProvider>
    </div>
  );
};
