import { useQuery } from "graphql-hooks";
import React from "react";
import { Image } from "react-datocms";
import { Link } from "react-router-dom";
import "./store.page.scss";

const ALL_PRODUCTS_QUERY = `query AllProducts {
    allProducts {
      slug
      name
      featuredImage {
        responsiveImage(imgixParams: { fit: crop, w: 600, h: 600, auto: format }) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            base64
          }
      }
      price
      smallDescription
    }
  }
  `;

interface Product {
  slug: string;
  name: string;
  featuredImage: {
    url: string;
    responsiveImage: any;
  };
  price: number;
  smallDescription: string;
}

interface QueryResponse {
  allProducts: Product[];
}

export const StorePage: React.FC = () => {
  const { loading, error, data } = useQuery<QueryResponse>(ALL_PRODUCTS_QUERY);

  if (loading) return <></>;
  if (error) return <>Something Bad Happened</>;

  const renderProducts = data.allProducts.map((p) => {
    return (
      <Link to={`/store/${p.slug}`} className="product" key={p.slug}>
        <Image data={p.featuredImage.responsiveImage} />
        <p>{p.name}</p>
        <p>€{p.price}</p>
      </Link>
    );
  });

  return (
    <div id="store">
      <div className="products-container">{renderProducts}</div>
    </div>
  );
};
