import { mdiFacebook, mdiInstagram, mdiTwitter } from "@mdi/js";
import { Icon } from "@mdi/react";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./navbar.scss";

interface NavBarProps {}
export const NavBar: React.FC<NavBarProps> = () => {
  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container is-fluid">
        <div className="navbar-menu">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <img src={logo} alt="I AM BORN SLIPPY" />
            </Link>
          </div>
          <div className="navbar-start">
            <NavLink
              to="/about"
              className="navbar-item"
              activeClassName="selected"
            >
              <span>About</span>
            </NavLink>

            <NavLink
              to="/store"
              className="navbar-item"
              activeClassName="selected"
            >
              <span>Store</span>
            </NavLink>
            <NavLink
              to="/contact"
              className="navbar-item"
              activeClassName="selected"
            >
              <span>Contact</span>
            </NavLink>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <Icon path={mdiInstagram} />
            </div>
            <div className="navbar-item">
              <Icon path={mdiFacebook} />
            </div>
            <div className="navbar-item">
              <Icon path={mdiTwitter} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
