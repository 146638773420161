import React from "react";
import "./about-page.scss";

export const AboutPage: React.FC = () => {
  return (
    <section className="section" id="about-page">
      <div className="container">
        <div className="content is-medium">
          <p>
            We provide a range of solutions to meet the needs of an
            environmentally conscious mindset by using a circular economy and
            transparent supply chain.
          </p>
        </div>
        <div className="content is-split">
          <p>
            Inspired by, and focussed on the surfing community at the
            intersection of design and function, Born Slippy is a{" "}
            <strong>BCORPORATION</strong> and change agent to provide awareness
            for oceanic ecosystem protection.
          </p>
          <p>
            Philosophically rooted in Europe with a minimal aesthetic and the
            idea that good design is functional and timeless. Our sensibilities
            are formed through active participation of contemporary culture
            along with love for nature, quality, style, design, function,
            sustainability, culture, anthropology, world travel, ecosystems and
            improving the lives of all species through continuous progress.
          </p>
          <p>
            We are inspired by everything from the planet and the subtle
            landscapes on the horizon. Each product is developed with an
            emphasis of relevance relating to its essential nature; from the
            ingredients to the function it must perform.
          </p>
          <p>
            Our aim is to strengthen the brand’s strength and reach through
            careful development of both its product portfolio and its online
            footprint delivering excellence across product, service and
            experience.
          </p>
        </div>
      </div>
    </section>
  );
};
