import React from "react";
import { Link } from "react-router-dom";
import onepercent from "../../assets/images/partners/1-for-the-planet.png";
import bcorp from "../../assets/images/partners/b-corp.png";
import "./footer.scss";

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column">
            <Link to="/donate" className="button is-large">
              DONATE
            </Link>
          </div>
          <div className="column partners">
            <a
              href="https://bcorporation.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={bcorp} alt="Partner B Corp" />
            </a>
            <a
              href="https://www.onepercentfortheplanet.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={onepercent} alt="Partner 1% for the Planet" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
