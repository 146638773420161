import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "graphql-hooks";
import { Image } from "react-datocms";
import "./product.page.scss";

const PRODUCT_QUERY = `query Product($slug: String) {
    product(filter: {slug: {eq: $slug}}) {
      images {
        responsiveImage(imgixParams: { fit: crop, w: 600, h: 600, auto: format }) {
            srcSet
            webpSrcSet
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            base64
          }
      }
      name
      price
      description
    }
  }
  `;

interface Product {
  name: string;
  price: number;
  description: string;
  images: any[];
}

interface QueryResponse {
  product: Product;
}

export const ProductPage: React.FC = () => {
  const { slug } = useParams();

  const { loading, error, data } = useQuery<QueryResponse>(PRODUCT_QUERY, {
    variables: {
      slug,
    },
  });

  if (loading) return <></>;
  if (error) return <>Something Bad Happened</>;

  const { product } = data;

  return (
    <div id="product">
      <div className="product-container">
        <div className="column images">
          <Image data={product.images[0].responsiveImage} className="image" />
        </div>
        <div className="column description">
          <h1 className="title">{product.name}</h1>
          <p>{product.description}</p>
          <button className="button is-large is-fullwidth">
            Buy €{product.price}
          </button>
        </div>
      </div>
    </div>
  );
};
